<template>
  <div>
    <b-modal
      scrollable
      id="ModelDelete"
      ref="ModelDelete"
      @hidden="resetModal()"
    >
      <template #modal-header>
        <h5>{{ $t("DELETE") }}</h5>
        <b-button size="sm" @click="resetModal()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>

      <form @submit.prevent="Delete" v-if="elemToDelete">
        <h2 class="main-color text-center">{{ $t("DELETE") }} ?</h2>
        <div class="mb-4 text-center red">
          <font-awesome-icon icon="trash" style="font-size: 30px" />
        </div>
      </form>
      <template #modal-footer>
        <b-alert variant="warning" show v-if="errorD">
          {{ errorD }}
        </b-alert>
        <div class="actionModel">
          <b-button
            variant="success"
            @click="Delete"
            v-if="
              isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
            "
          >
            <div class="block-spinner">
              {{ $t("DELETE") }}
              <div v-if="loader" class="loading">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      errorD: null,
    };
  },

  props: {
    loader: Boolean,
    elemDelete: Function,
    elemToDelete: Object,
  },

  methods: {
    async Delete() {
      await this.elemDelete({
        id: this.elemToDelete.id,
      })
        .then(() => {
          this.$refs["ModelDelete"].hide();
          this.errorD = null;
          this.$emit("updateList");
        })
        .catch((err) => {
          this.errorD = err;
        });
    },
    resetModal() {
      this.errorD = null;
      this.$refs["ModelDelete"].hide();
    },
  },

  computed: {
    ...mapGetters(["getOnlineUser"]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
  },
};
</script>
